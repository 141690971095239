import { preventDefault, preventDefaultThen } from 'components/link'
import * as Loading from 'components/loading'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import { isEmpty } from 'lodash'
import React from 'react'
import { Button } from 'reactstrap'
import * as Redux from 'redux'
import { SourceTypes } from 'shared_server_client/constants'
import { injectReducer } from 'store/index'
import { DEFAULT_ARTICLE_DESCRIPTION_CAP } from '../constants'
import { Article } from '../utility/components'
import { postAddSource } from '../utility/utils'
import './_styles.scss'
import * as Constants from './constants'

interface AddFeedState {
  error?: string,
  articles: any,
  found: boolean,
  isLoading: boolean,
  name: string,
  selectedCommunity: string,
  topic: string,
}

class ContentPoolAddTopicSourceComponent extends RasaReactComponent<any, AddFeedState> {
  public static contextType = RasaContext;
  constructor(props: any) {
    super(props, 'communitySource', {
      articles: [],
      found: true,
      isLoading: false,
      name: '',
      selectedCommunity: '',
      topic: '',
    })
  }

  public componentDidMount = () => {
    this.context.user.init().then(({person, activeCommunity}) => {
      this.selectCommunity(activeCommunity.communityId)
      this.setState({
        selectedCommunity: activeCommunity.communityId,
      })
    })
  }

  public render = () => {
    return <div className={`${this.context.store.getState().config.isMobile ? 'add-topic-wrapper-mobile' : 'add-topic-wrapper'}`}>
      <h2 className="sources-heading">Sources</h2>
      <div className="section-header-text">Add a New {Constants.SOURCE_NAME} Feed</div>
      <p className="section-detail-text">Specify some topics, and let t-rex find some content.</p>
    { isEmpty(this.state.articles) ?
      <div className="topic sources">
        <div className="image">
          <i className="fa fa-list fa-2" aria-hidden="true"></i>
        </div>
        <div className="words">
          <h2>{Constants.SOURCE_NAME}</h2>
          <p>Specify some topics for your newsletter</p>
          <div className="input-area">
            <form onSubmit={preventDefaultThen(() => this.searchSource())}>
              <input autoFocus disabled={this.state.isLoading} className="field-textbox"
                     value={this.state.topic}
                     onChange={(e: any) => this.setState({ topic: e.target.value })}/>
              <Button disabled={this.state.isLoading || !this.isValidTopic()}>Next</Button>
            </form>
          </div>
        {  !this.state.found &&
          <span className="warning">
            {this.state.error || 'We could not locate any articles for this topic'}
          </span>
        }
        </div>
      </div>
    :
      <div className="">
        <div className="topic confirm sources">
          <div className="image">
            <i className="fa fa-list fa-2" aria-hidden="true"></i>
          </div>
          <div className="words">
            <h2>{Constants.SOURCE_NAME} feed</h2>
            <p>
              <strong>We found some content for you.</strong>
            </p>
            <p>Specify how you would like this source to be named in your newsletter and hit confirm.</p>
            <div className="input-area">
              <form onSubmit={preventDefault()}>
                <input autoFocus className="field-textbox"
                  value={this.state.name}
                  onChange={(e: any) => this.setState({ name: e.target.value })}/>
                <Button onClick={() => this.addSource()}
                        disabled={this.state.isLoading || !this.state.name}>Confirm</Button>
                <Button className="change" onClick={() => this.setState({articles: {}})}>Change</Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    }
    { this.state.isLoading &&
      <Loading.Loading size={Loading.SIZES.LARGE}/>
    }
    { !isEmpty(this.state.articles) &&
      <div className={`${this.context.store.getState().config.isMobile ? 'recent-articles-mobile' : 'recent-articles'}`}>
        <h4>Example Articles</h4>
      { this.state.articles.map((article, i) =>
        <Article key={i} article={article} descriptionCap={DEFAULT_ARTICLE_DESCRIPTION_CAP}/>)
      }
      </div>
    }
    </div>
  }

  public searchSource() {
    this.setState({isLoading: true}, () =>
      AjaxWrapper
        .ajax(`${AjaxWrapper.getServerUrl()}/topic/${this.state.selectedCommunity}/find`, HttpMethod.POST, {
          application: 'dashboard',
          topic: this.state.topic,
          identifier: this.state.selectedCommunity,
          limit: 5
        })
        .then((feed) => this.setState({
          articles: feed.items,
          found: true,
          isLoading: false,
          name: `News about '${this.state.topic}'`,
        }))
        .catch((err) => {
          this.setState({
            error: err.response ? err.response.message : 'We could not locate any articles for this topic',
            articles: [],
            found: false,
            isLoading: false,
          })
        }))
  }

  public addSource() {
    const source = {
      community_id: this.context.user.activeCommunity.communityId,
      identifier: this.state.topic,
      name: this.state.name,
      type: SourceTypes.topic
    }
    this.props.propertiesChanged(source)
    this.saveRecord(null).then(() => {
      postAddSource(this.context, 'google', source.identifier, this.props.push)
    })
  }

  private isValidTopic() {
    return this.state.topic.length > 3;
  }
}

export const ContentPoolAddTopicSource = GenericRedux.createConnect(
  ContentPoolAddTopicSourceComponent,
  Constants.REDUX_STORE_HOME,
)
injectReducer(
  Constants.REDUX_STORE_HOME,
  Redux.combineReducers({
    data: GenericRedux.createGenericReducer(
      Constants.REDUX_STORE_HOME,
      {url: ''},
    ),
  }),
)
